.AboutExtended {}

.about-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.about-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
    margin-top: 30px;
}

.section-heading {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 50px;
}

.conductor-image,
.choir-image {
    padding: 15px;
}

.conductor-image img,
.choir-image img {
    width: 90%;
}

.choir-desc,
.conductor-desc {
    text-align: justify;
    padding: 25px !important;
}

.divider img {
    height: 130px;
}

.about-section-title {
    font-family: 'DMSans-Bold';
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {

    .divider img {
        height: 50px;
    }

    .conductor-content {
        flex-direction: column-reverse;
    }

    .conductor-image img,
    .choir-image img {
        width: 100%;
    }
}