.Testimonials {
    background-color: var(--bg-dark);
    padding: 20px 10px;
}

.testimonials-title {
    font-family: 'DMSans-Bold';
    font-size: 25px;
    color: var(--text-secondary);
    width: fit-content;
}

.testimonial-item {
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    padding: 10px 15px;
}

.testimonial-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.testimonial-avatar {
    width: 100%;
    border-radius: 50%;
}

.testimonial-title {
    font-family: 'DMSans-Bold';
    font-size: 20px;
}

.testimonial-designation {
    font-family: 'DMSans-Medium';
    font-size: 12px;
    color: var(--text-tertiary);
}

.testimonial-desc {
    font-size: 13px;
    font-family: 'DMSans-Regular';
    color: var(--text-primary);
}

.testimonial-date {
    font-family: 'DMSans-Medium';
    font-size: 12px;
    color: var(--text-tertiary);
}