body {
  margin: 0;
  font-family: 'DMSans-Regular' !important;
  background-color: var(--bg-primary);
}

/* Global CSS Configurations */
:root {
  --overlay-default: #000000;
  --text-primary: #000000;
  --text-secondary: #ffffff;
  --text-tertiary: #6A6A6A;
  --bg-primary: #FFFFFF;
  --bg-dark: #222222;
  --theme-primary: #FC5252;
  --color-success: #46894b;
}

/* ScrollBar Config For Body */
/* width */
::-webkit-scrollbar {
  width: 5px;
}


/* Hide number field arrows globally */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number field arrows globally */
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(44, 44, 44);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-dark);
  border-radius: 10px;
}


/* Global Font Mapping */
@font-face {
  font-family: 'DMSans-Regular';
  src: url('assets/fonts/DM_Sans/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DMSans-Medium';
  src: url('assets/fonts/DM_Sans/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'DMSans-Bold';
  src: url('assets/fonts/DM_Sans/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('assets/fonts/Montserrat/static/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('assets/fonts/Montserrat/static/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('assets/fonts/Montserrat/static/Montserrat-Thin.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('assets/fonts/Montserrat/static/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('assets/fonts/Inter/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('assets/fonts/Inter/Inter-Regular.ttf');
}