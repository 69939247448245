.MemberArea {}

.tab-item {
    border: solid 1px var(--bg-dark);
    color: var(--theme-seondary);
    font-family: 'DMSans-Bold';
    padding: 7px 12px;
    cursor: pointer;
}

.tab-item.active {
    color: var(--text-secondary);
    background-color: var(--bg-dark);
}

.welcome-name {
    text-transform: none !important;
}

.more-updates-section {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: 0.3s;
    padding: 15px;
    background: var(--color-success);
    font-family: 'DMSans-Bold';
    color: var(--text-secondary);
}