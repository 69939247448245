.MediaExtended {}

.media-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.media-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
}

.media-item {
    display: flex;
    align-items: center;

}

.media-item img {
    width: 100%;
}