.Footer {
    background-color: var(--bg-dark);
    padding: 25px 10px;
    font-family: 'DMSans-Medium';
    text-align: center;
    color: var(--text-secondary);
    position: relative;
}

.Footer h4 {
    font-family: 'DMSans-Bold';
}

.contact-illustration {
    width: 75%;
}

.contact-details {
    text-align: left;
}

.social-media-icons img {
    height: 25px;
    cursor: pointer;
}

.privacy-policy,
.cancellation-refund-policy,
.terms-and-conditions {
    cursor: pointer;
}

.policies {
    flex-direction: row;
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .policies {
        flex-direction: column;
    }

    .pipe {
        display: none;
    }
}