.form-title {
    font-size: 32px;
    font-family: 'DMSans-Bold';
}

/* Certificate preview */
.certificate-img {
    width: inherit;
    height: inherit;
}

.certificate-hide {
    display: none;
}

.certificate-show {
    display: block;
}

.certificate-btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.certificate-preview-wrapper {
    margin: 50px 0;
    border: solid 1px var(--text-tertiary);
    padding: 20px;
}

.certificate-name {
    position: absolute;
    top: 734px;
    left: 134px;
    color: #8ccdce;
    font-family: 'DMSans-Bold';
    width: 73%;
    /* background: red; */
    text-align: center;
    height: 106px;
    font-size: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}