.ResourceArea {}

.ResourceArea iframe {
    height: 200px;
}

.video-display-name {
    font-size: 17px;
    font-family: 'DMSans-Bold';
}

.video-detail {
    border: solid 1px #bfbfbf;
    height: 100%;
}

.wrap {
    height: inherit;
}