.ResourceSongs iframe {
    height: 200px;
}

.events-header-label {
    margin-left: 0px;
}

.view-label {
    font-size: 12px;
}

.view-label:hover {
    text-decoration: underline;
}

.notes-section {
    border: solid 1px #bfbfbf;
}

.notes-section label {
    font-size: 13px;
}

.resources-item-composer {
    color: var(--text-tertiary);
    font-family: 'DMSans-Medium';
    font-size: 10px;
    margin: 0;
    cursor: pointer;
}

.song-details {
    display: flex;
    flex-direction: column;
}