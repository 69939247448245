.Resources {}

.resources-wrapper {
    color: var(--bg-dark);
}

.resources-event-item-wrapper,
.resources-item-label {
    cursor: pointer;
}

.resources-event-item {
    border: solid 1px #bfbfbf;
    border-radius: 2px;
    padding: 5px 10px;
}

.resources-icon {
    width: 50px;
    height: 50px;
}

.resources-item-label {
    font-family: 'DMSans-Bold';
    font-size: 14px;
}

.events-header-label {
    font-size: 20px;
}

.resources-wrapper nav ol li a:hover {
    color: #222222 !important;
}

.resources-wrapper nav ol li {
    cursor: pointer;
}

.view-label {
    cursor: pointer;
    width: fit-content;
}

.no-pointer {
    cursor: default !important;
}

/* Media Query for screen adoption */
@media only screen and (max-width: 600px) {
    .resources-item-label {
        font-size: 15px;
    }
}

@media (min-width: 700px) and (max-width: 800px) {
    .resources-item-label {
        font-size: 15px;
    }
}