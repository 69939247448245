.seat-class-label {
    color: var(--text-tertiary);
    font-family: 'DMSans-Regular';
}

.seat-classes {
    display: flex;
    gap: 10px;
}

.seat-classes div {
    width: 100%;
}

.seat-classes img {
    width: 40px;
    height: 40px;
}

.a-class,
.b-class,
.c-class {
    background: linear-gradient(to left bottom, #db4040, rgb(70 0 0));
    border: solid 1px rgb(70 0 0);
}

.class {
    display: flex;
    gap: 15px;
    position: relative;
}

.class h4,
.price {
    font-size: 14px;
    color: #FFF;
    font-family: 'DMSans-Medium';
}

.price {
    font-size: 23px;
    font-family: 'DMSans-Bold';
    margin-bottom: 0;
}

.price label {
    font-size: 10px;
    font-family: 'DMSans-Regular';
}

.class input {
    width: 4%;
}

input[type='radio'] {
    transform: scale(2);
}

.labels a {
    font-size: 14px;
    color: var(--theme-primary);
}

.labels a:hover {
    font-size: 14px;
    color: var(--theme-primary);
}

.labels {
    align-items: center;
    justify-content: space-between;
}

.proceed-btn {
    background: linear-gradient(to left bottom, #db4040, rgb(70 0 0));
    border: solid 1px rgb(70 0 0);
    display: flex;
    align-items: center;
    padding: 10px 10px;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;
    box-shadow: 0 0 20px 10px rgb(252 82 82 / 20%);
}

.lsm-event-logo {
    width: 15%;
}

.confirmation h4 {
    font-size: 25px;
}

.confirmation-footer h4 {
    font-size: 20px;
    margin-bottom: 0;
}

.email-disclaimer {
    font-size: 14px;
    color: #9b2929;
}

.title {
    font-size: 35px;
    font-family: 'DMSans-Bold';
}

.seat-map-wrapper {
    padding: 0 0 15px 5px;
}

.seat-map {
    width: 100%;
}

.important-notes {
    padding: 10px 0;
    border: solid 1px var(--text-tertiary);
}

@media (max-width: 768px) {
    .seat-classes {
        display: block;
    }

    .lsm-event-logo {
        width: 35%;
    }
}