.SetPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--bg-dark);
}

.setPassword-wrapper {
    box-shadow: rgba(215, 190, 162, 0.2) 0px 54px 55px, rgba(215, 190, 162, 0.2) 0px -12px 30px, rgba(215, 190, 162, 0.2) 0px 4px 6px, rgba(215, 190, 162, 0.2) 0px 12px 13px, rgba(215, 190, 162, 0.2) 0px -3px 5px;
    transition: 0.3s;
    background-color: var(--text-secondary);
    border-radius: 4px;
}

.setPassword-wrapper img {
    width: inherit;
    height: 150px;
    object-fit: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.set-up-label {
    font-family: 'DMSans-Bold';
}

.password-criterion-label {
    font-size: 12px;
    color: var(--text-tertiary);
}