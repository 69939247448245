.About {
    padding: 90px 0;
}

.about-details {
    padding: 5px 30px !important;
}

.about-label {
    font-family: 'DMSans-Bold';
    font-size: 25px;
}

.about-desc {
    font-family: 'DMSans-Regular';
    font-size: 13px;
    text-align: justify;
    color: var(--text-tertiary);
}

.about-img-wrapper {
    text-align: right;
}

.about-img {
    height: 300px;
    object-fit: cover;
    width: 100%;
    border-radius: 3px;
}

.know-more-btn {
    width: fit-content;
    background-color: var(--theme-primary);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;
    box-shadow: 0 0 20px 10px rgba(252, 82, 82, 0.2);
}

.arrow-icon {
    width: 15px;
    height: 15px;
    margin-left: 7px;
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .About {
        padding: 50px 0;
    }
}