.Blogs {
    padding: 40px 0;
}

.Blogs h4 {
    font-family: 'DMSans-Bold';
}

.blogs-sub-text {
    color: var(--text-tertiary);
    font-family: 'DMSans-Regular';
    font-size: 16px;
}

.blogs-item-wrapper {
    padding: 0 25px;
}

.home-blogs-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
}

.blogs-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


.blogs-img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 3px;
}

.home-blogs-title {
    font-family: 'DMSans-Bold';
    font-size: 18px;
}

.home-blogs-desc {
    font-family: 'DMSans-Medium';
    font-size: 13px;
    color: var(--text-tertiary);
}

.home-blogs-author {
    font-family: 'DMSans-Bold';
    font-size: 13px;
    font-style: italic;
}

.home-blogs-date {
    font-family: 'DMSans-Bold';
    font-size: 13px;
    color: var(--text-tertiary);
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .Blogs {
        padding: 20px 0;
    }
}