.Quotes {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding: 50px 150px;
    font-family: 'DMSans-Bold';
    font-size: 40px;
    text-align: center;
}

.quotes-icon {
    width: 15%;
}

hr {
    height: 5px !important;
    border-radius: 10px;
    width: 26%;
    margin: auto !important;
    color: var(--theme-primary) !important;
}

.author-label {
    text-transform: uppercase;
    font-family: 'DMSans-Medium';
    letter-spacing: 2px;
    font-size: 15px;
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .Quotes {
        padding: 50px;
    }
}