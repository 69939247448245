.Store h4 {
    font-family: 'DMSans-Bold';
}

DMSans-Medium .store-item-wrapper {
    padding: 0 10px;
}

.store-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
}

.store-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


.store-img {
    width: 80%;
    border-radius: 3px;
    margin: auto;
}

.store-item-title {
    font-family: 'DMSans-Bold';
    font-size: 17px;
    color: #222222;
    text-align: left;
}

.store-item-details {
    text-align: left;
}

.store-item-price {
    font-family: 'Inter-Bold';
}

.store-shipping-detail {
    font-family: 'Inter-Regular';
    font-size: 11px;
    color: var(--text-tertiary);
}

.buy-btn {
    border: solid 1px var(--theme-primary);
    color: var(--theme-primary);
    padding: 5px 10px;
    font-family: 'DMSans-Bold';
}

.cart-icon {
    width: 20px;
    height: 20px;
}