.Media {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding: 130px 0;
}

.media-label {
    font-family: 'DMSans-Bold';
    color: var(--text-secondary);
}

.media-desc {
    font-family: 'DMSans-Regular';
    font-size: 13px;
    text-align: justify;
    color: #b7b3b3;
    padding-right: 70px !important;
}

.media-photos img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: cover;
    box-shadow: 0 4px 8px 4px rgba(215, 190, 162, 0.2);
}

.know-more-btn {
    width: fit-content;
    background-color: var(--theme-primary);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;
    box-shadow: 0 0 20px 10px rgba(252, 82, 82, 0.2);
}

.arrow-icon {
    width: 15px;
    height: 15px;
    margin-left: 7px;
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .Media {
        padding: 40px 0;
    }

    .know-more-btn {
        margin-bottom: 20px;
    }
}