.Home {
    position: relative;
    color: var(--text-secondary);
    height: 100vh;
    background-image: url("../../assets/banner-image.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-description {
    font-family: 'Inter-SemiBold';
}

.banner-text {
    font-family: 'Montserrat-Bold';
    font-size: 35px;
    text-align: center;
}

.banner-sub-text {
    font-family: 'Montserrat-Bold';
}

.banner-logo {
    position: absolute;
    bottom: 15px;
    left: 5px;
    width: 15%;
}

.sub-text-wrapper {
    cursor: pointer;
}

.play-icon {
    width: 35px;
    height: 35px;
}

.Navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}