.RegistrationsClosed {
    background: var(--bg-dark);
    color: #9d9e9e;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.logo_s {
    width: 5%;
}

.contact-wrapper {
    margin: 0;
    justify-content: center;
}