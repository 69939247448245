.Login {}

.login-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.login-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
    margin-top: 30px;
}

.login-wrapper {
    box-shadow: rgba(215, 190, 162, 0.2) 0px 54px 55px, rgba(215, 190, 162, 0.2) 0px -12px 30px, rgba(215, 190, 162, 0.2) 0px 4px 6px, rgba(215, 190, 162, 0.2) 0px 12px 13px, rgba(215, 190, 162, 0.2) 0px -3px 5px;
    transition: 0.3s;
    background-color: var(--text-secondary);
    border-radius: 4px;
    border: solid 1px grey;
}

.login-wrapper img {
    width: inherit;
    height: 150px;
    object-fit: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.MuiTextField-root {
    width: 100% !important;
}

.forgot-password-label {
    text-align: right;
}

.forgot-password-label label {
    color: var(--theme-primary);
    text-decoration: underline !important;
    cursor: pointer;
}