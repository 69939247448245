.PrivacyPolicy {}

.privacy-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.privacy-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 45px;
    margin-top: 30px;
}

.policy-desc {
    text-align: justify;
}