.EventsExtended h3 {}

.events-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.events-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
    margin-top: 30px;
}

.no-events-found {
    background: var(--text-tertiary);
    padding: 20px 30px;
    color: var(--text-secondary);
    text-align: center;
    border: solid 1px var(--bg-dark);
}


.event-item-wrapper {
    padding: 0 10px;
}

h4 {
    font-family: 'DMSans-Bold';
}

.event-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
    height: 100%;
}

.event-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


.event-img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 3px;
}

.event-title {
    font-family: 'DMSans-Bold';
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.event-desc {
    font-family: 'DMSans-Regular';
    font-size: 13px;
    color: var(--text-tertiary);
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.event-month {
    color: var(--theme-primary);
    font-family: 'DMSans-Bold';
    font-size: 13px;
    letter-spacing: 1px;
}

.event-date {
    font-family: 'DMSans-Bold';
    font-size: 28px;
}

.event-year {
    font-family: 'DMSans-Bold';
    font-size: 13px;
    color: var(--text-tertiary);
}

.divider img {
    height: 130px;
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .events-banner-title {
        margin-top: 30px;
    }

    .divider img {
        height: 50px;
    }
}