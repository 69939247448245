.Attendees {}

.attendees-item {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 15px 20px;
}

.attendees-item h3 {
    font-family: 'DMSans-Bold';
    font-size: 20px;
    margin-bottom: 0;
}

.attendees-item p {
    font-size: 14px;
}

.checkInButton {
    color: var(--text-secondary);
    width: fit-content;
    font-family: 'DMSans-Bold';
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
}

.green-btn {
    background-color: #337633;
}

.red-btn {
    background-color: #b53a3a;
}