.Contact {}

.contact-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.contact-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
    margin-top: 30px;
}

.contact-card {
    box-shadow: 0 4px 8px 4px rgba(34, 34, 34, 0.2);
    background: rgba(34, 34, 34, 1.0);
    transition: 0.3s;
    border-radius: 3px;
    border: solid 1px grey;
    padding: 10px 5px;
}

.contact-card h3 {
    color: var(--text-secondary);
    font-size: 17px;
    font-family: 'DMSans-Bold';
}

.mail-icon {
    width: 15%;
}

.location-icon {
    width: 18%;
}

.phone-icon {
    width: 10%;
    margin: 10px 0;
}

.mobile-layout {
    display: none !important;
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .contact-banner-title {
        margin-top: 30px;
    }

    .web-layout {
        display: none !important;
    }

    .mobile-layout {
        display: block !important;
    }
}