.Payment {}

.payment-section {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 10px 5px;
}

.payment-label {
    font-family: 'DMSans-Bold';
    font-size: 18px;
    padding: 10px;
}

.payment-status-badge {
    font-size: 13px;
    padding: 5px 15px;
    border-radius: 25px;
    border: solid 1px var(--text-tertiary);
}

.payment-status-indicator {
    height: 13px;
    width: 13px;
    border-radius: 50%;
    margin-right: 8px;
}

.razor-pay-action-wrapper {
    padding: 25px 15px;
    border: solid 1px grey;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    margin: 20px 0 15px 0;
}

.payment-done {
    padding: 25px 15px;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    margin: 20px 0 15px 0;
    background: #77DD76;
    text-align: center;
    font-size: 22px;
}

.payment-done img {
    width: 5%;
}

.razor-pay-action {
    padding: 8px 15px;
    border-radius: 3px;
    background: var(--theme-primary);
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    width: fit-content !important;
    cursor: pointer;
}

.event-payment-item {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
    height: 100%;
}

.pay-btn {
    background-color: var(--theme-primary);
    color: var(--text-secondary);
    width: fit-content;
    font-family: 'DMSans-Bold';
    font-size: 13px;
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 0 20px 10px rgba(252, 82, 82, 0.2);
}

.paid-btn {
    background-color: var(--color-success) !important;
    box-shadow: 0 0 20px 10px rgba(70, 137, 75, 0.2) !important;
}