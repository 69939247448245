.App {
    overflow-x: hidden;
}

.load-more,
.contact-us,
.pay-register-btn {
    background-color: var(--theme-primary);
    color: var(--text-secondary);
    width: fit-content;
    font-family: 'DMSans-Bold';
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 0 20px 10px rgba(252, 82, 82, 0.2);
}

.status {
    padding: 6px;
    border-radius: 50%;
}

.status-green {
    background-color: #0CC078;
}

.status-yellow {
    background-color: #ffff76;
}

.status-red {
    background-color: #FB6962;
}

.MuiTypography-root {
    font-family: 'DMSans-Bold' !important;
}

.MuiDialogContentText-root {
    font-family: 'DMSans-Regular' !important;
    letter-spacing: 0 !important;
}