.BlogsExtended {}

.blogs-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.blogs-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
    margin-top: 30px;
}

.blogs-sub-text {
    color: var(--text-tertiary);
    font-family: 'DMSans-Regular';
    font-size: 16px;
}

.blogs-item-wrapper {
    padding: 0 25px;
}

.blogs-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
    border: solid 1px var(--text-tertiary);
}

.blogs-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


.blogs-img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 3px;
}

.blogs-title {
    font-family: 'DMSans-Bold';
    font-size: 23px;
}

.blogs-desc {
    font-family: 'DMSans-Medium';
    font-size: 13px;
    color: var(--bg-dark) !important;
    text-align: justify !important;
}

.blogs-author {
    font-family: 'DMSans-Bold';
    font-size: 13px;
    font-style: italic;
    color: var(--text-tertiary);
}

.blogs-date {
    font-family: 'DMSans-Bold';
    font-size: 13px;
    color: var(--text-tertiary);
}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .blogs-title {
        font-size: 18px;
    }
}