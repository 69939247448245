.Loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #222222;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Loader img {
    width: 20%;
}

.linear-progress-item {
    margin-top: 25px;
    width: 20%;
}

.MuiLinearProgress-root {
    background-color: #676849 !important;
}

.MuiLinearProgress-bar {
    background-color: #383323 !important;
}

/* Media Query for screen adoption */
@media only screen and (max-width: 600px) {

    .linear-progress-item,
    .Loader img {
        width: 50%;
    }
}

@media (min-width: 700px) and (max-width: 800px) {

    .linear-progress-item,
    .Loader img {
        width: 50%;
    }
}